import { useContext } from 'react';
import UserContext from 'context/UserContext';
import { useRouter } from 'next/router';

import {Form, Input, GridContainer, GridItem, Link, Body } from 'element'
import * as routes from 'routes';
import { login, identify, LoginParams } from 'requests/auth';

const LoginForm = () => {
  const userContext = useContext(UserContext);
  const router = useRouter();

  const submitLogin = async (data: LoginParams) => {
    const loginResult = await login({login: data});
    const { success } = loginResult;
    if (success) {
      //const userData = await identify();
      //await userContext.setUserId(userData.id)
      await userContext.loadUserFromSession();
      router.push('/')
    }
  }

  return <GridContainer column>
    <GridItem>
      <Form onSubmit={submitLogin} submitLabel="Log In">
        {(formContext)=> {
          return <GridContainer>
            <GridItem xs={12}>
              <Input submitOnEnter fullWidth name="username" label="Email" formContext={formContext} instructions="Enter your email"/>
            </GridItem>
            <GridItem xs={12}>
              <Input submitOnEnter fullWidth name="password" type="password" label="Password" instructions="Enter your password" formContext={formContext} />
            </GridItem>
          </GridContainer>
        }}      
      </Form>
    </GridItem>
    <GridItem>
      <Body textAlign="center">
        <Link href={routes.requestPasswordReset()}>Forgot password?</Link>
      </Body>
    </GridItem>
  </GridContainer>
  
}

export default LoginForm;